<template>
  <div class="container-mentions">
    <span class="link" @click="isOpen = !isOpen">Mention legal CNIL</span>
    <div v-if="isOpen" class="content">
      <div class="line-container">
        <p class="text">
          Les informations recueillies sur ce formulaire sont enregistrées dans un fichier
          informatisé par MARCO VASCO pour la communication de l'actualité et des offres
          promotionnelles MARCO VASCO à partir du moment où le contact à donné son consentement
          explicite. Elles sont conservées pendant 3 ans et sont destinées au service marketing et
          commercial établis au sein de l'union européenne. Conformément à la loi « informatique et
          libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les
          faire rectifier en contactant : MARCO VASCO Service Marketing et communication 101 rue de
          l'Abbé Groult 75015 Paris
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const isOpen = ref(false);
</script>

<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/metrics.scss';
@use '$/misc.scss';
@use '$/colors.scss';

.container-mentions {
  flex-direction: column;
  width: 100%;
  margin: 40px 0;

  @include breakpoints.mobile() {
    align-self: flex-start;
    width: 100vw;
    margin-left: 0;
    padding-left: 16px;
  }

  .link {
    @include misc.bold-link();

    cursor: pointer;
  }

  .content {
    width: 100%;
    margin: 40px 0;

    @include breakpoints.mobile() {
      margin: 0;
    }

    .line-container {
      padding-left: 20px;
      border-left: 3px dashed colors.$primary-red;

      @include breakpoints.mobile() {
        padding: 16px 16px 16px 0;
        border-left: none;
      }

      .text {
        text-align: justify;

        @include breakpoints.mobile() {
          padding-right: 50px;
        }
      }
    }
  }
}
</style>
